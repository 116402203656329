import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import Geolocation from "react-geolocation";
import axios from "axios";
import {
  GET_TOKEN,
  API_DAFTARHADIR_CEK,
  API_TIMEOUT,
  API_DAFTARHADIR,
  GET_DAFTARHADIR_LIBUR,
  GET_DAFTARHADIR_ATURAN,
  GET_DAFTARHADIR_KOORDINATOPD,
  GET_INSTANSI,
} from "utils";
import { getUserDHO } from "actions/GetUserAction";
import {
  catatMasuk,
  catatPulang,
  clearCatatPulang,
} from "actions/DaftarHadirAction";
import { dateTime } from "utils";
import { GET_DH_ATURAN } from "utils";
import { getTimeKu } from "utils";

export default function CatatDH() {
  const dispatch = useDispatch();

  const { catatMasukResult, catatPulangResult } = useSelector(
    (state) => state.DaftarHadirReducer
  );
  const history = useHistory();

  const [unitKerja, setUnitKerja] = useState("");
  const [nama, setNama] = useState("");
  const [waktuMasukKu, setWaktuMasukKu] = useState("");

  const [waktuPulang, setWaktuPulang] = useState("");
  const [status, setStatus] = useState("");
  const [avatar, setAvatar] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [workingHours, setWorkingHours] = useState(0);
  const [idDH, setIdDH] = useState("");
  const [edit, setEdit] = useState(false);
  const [dateTimeKu, setDateTimeKu] = useState("");
  const [uid, setUid] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [bulan, setBulan] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [sementaraLoading, setSementaraLoading] = useState(false);

  const [hariIni, sethariIni] = useState("");
  const [tahun, setTahun] = useState("");
  const [labelBulan, setLabelBulan] = useState("");

  const [catatMasukKu, setCatatMasukKu] = useState(false);
  const [catatPulangkuKu, setCatatPulangKu] = useState(false);

  const [aturanMasuk, setAturanMasuk] = useState("");
  const [aturanPulang, setAturanPulang] = useState("");
  const [jarakMasuk, setJarakMasuk] = useState(0);
  const [jarakPulang, setJarakPulang] = useState(0);

  const [libur, setLibur] = useState(false);
  const [hariLibur, setHariLibur] = useState("");

  // auth
  const [coordsKantor, setcoordsKantor] = useState({
    lat: 37.8333333335,
    long: -122.0800000000002,
  });

  const [accessToken, setAccessToken] = useState("");

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + "/token", {
        credentials: "include",
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push("/admin/daftarhadir");
      }
    }
  };

  //verifikasi

  const verifikasi = () => {
    if (catatMasukKu) {
      var limitPagi = aturanMasuk;

      const data = getTimeKu();

      const date = new Date(data.datetime);

      var month = (date.getMonth() + 1).toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      var day = date.getDate().toString();

      if (day.length === 1) {
        day = "0" + day;
      }
      var year = date.getFullYear().toString();
      var seconds = date.getSeconds().toString();
      if (seconds.length == 1) {
        seconds = "0" + seconds;
      }

      var hour = date.getHours().toString();
      if (hour.length == 1) {
        hour = "0" + hour;
      }

      var minute = date.getMinutes().toString();
      if (minute.length == 1) {
        minute = "0" + minute;
      }

      var waktuAbsen = hour + ":" + minute;
      var recentTime = waktuAbsen + ":" + seconds;

      var recentTimeFormat = new Date(
        year + "-" + month + "-" + day + "T" + recentTime
      );
      var limitPagiTimeFormat = new Date(
        year + "-" + month + "-" + day + "T" + limitPagi
      );
      var tanggalDH = year + "-" + month + "-" + day;
      if (recentTimeFormat > limitPagiTimeFormat && status == "Hadir") {
        swal(
          "Failed",
          "Anda telat tidak bisa melakukan pencatatan masuk",
          "error"
        );

        return;
      }

      const jarak = calcCrow(
        latitude,
        longitude,
        coordsKantor.lat,
        coordsKantor.long
      );

      if (jarak > jarakMasuk && status == "Hadir") {
        swal(
          "Failed",
          "Posisi anda berjarak " +
            jarak.toFixed(2) +
            " km dari lokasi kerja" +
            ". Status Hadir tidak bisa dicatat ke database",
          "error"
        );

        return;
      }
      // saveDaftarHadir(month, day, year, waktuAbsen, statusKu);

      catatMasuk(month, day, year, waktuAbsen, tanggalDH);
    } else {
      var limitPulang = aturanPulang;
      const data = getTimeKu();
      const date = new Date(data.datetime);
      var month = (date.getMonth() + 1).toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      var day = date.getDay().toString();

      if (day.length === 1) {
        day = "0" + day;
      }
      var year = date.getFullYear().toString();
      var seconds = date.getSeconds().toString();
      if (seconds.length == 1) {
        seconds = "0" + seconds;
      }

      var hour = date.getHours().toString();
      if (hour.length == 1) {
        hour = "0" + hour;
      }

      var minute = date.getMinutes().toString();
      if (minute.length == 1) {
        minute = "0" + minute;
      }

      var waktuAbsenPulang = hour + ":" + minute + ":" + seconds;
      var recentTime = waktuAbsenPulang;
      var waktuMasukModif = waktuMasukKu + ":00";
      const waktuAbsenPulangKu = hour + ":" + minute;
      var recentTimeFormat = new Date(
        "2023" + "-" + "11" + "-" + "11" + "T" + recentTime
      );
      var limitPulangTimeFormat = new Date(
        "2023" + "-" + "11" + "-" + "11" + "T" + limitPulang
      );

      var waktuPulangTimeFormat = new Date(
        "2023" + "-" + "11" + "-" + "11" + "T" + recentTime
      );

      var waktuMasukTimeFormat = new Date(
        "2023" + "-" + "11" + "-" + "11" + "T" + waktuMasukModif
      );

      var timeku = Math.round(
        waktuPulangTimeFormat.getHours() - waktuMasukTimeFormat.getHours()
      );
      var menitku = Math.round(
        waktuPulangTimeFormat.getMinutes() - waktuMasukTimeFormat.getMinutes()
      );

      timeku = Math.abs(timeku);
      timeku = timeku * 60;
      var whKu = timeku + menitku;
      var whKu2 = whKu / 60;

      whKu2 = whKu2.toFixed(2);
      var whKuString = whKu2.toString().split(".");
      var menitku2 = "0" + "." + whKuString[1];
      var menitku3 = parseFloat(menitku2);
      var menitku4 = menitku3 * 60;
      menitku4 = menitku4.toFixed(0);

      var jamKerja = whKuString[0] + ":" + menitku4;

      if (recentTimeFormat < limitPulangTimeFormat) {
        swal(
          "Failed",
          " Belum waktu pulang, tidak bisa melakukan  pencatatan pulang",
          "error"
        );

        return;
      }

      //latitude,
      //longitude,
      const jarak = calcCrow(
        latitude,
        longitude,
        coordsKantor.lat,
        coordsKantor.long
      );
      if (jarak > jarakMasuk) {
        swal(
          "Failed",
          " Posisi anda berjarak " +
            jarak.toFixed(2) +
            " km dari lokasi kerja" +
            ". Status Hadir tidak bisa dicatat ke database",
          "error"
        );

        return;
      }
      editDH(year, month, day, waktuAbsenPulangKu, jamKerja);
      //savePulang(year, month, day, waktuAbsenPulang, jamKerja);
    }
  };

  //verifikasi

  //dari Smart service

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  const ambilAturan = async (hariKerja, aturan) => {
    var data1 = [];

    try {
      const response = await axios.get(
        GET_DH_ATURAN + aturan + "/" + `${hariKerja}`
      );
      data1 = response.data.data;

      setAturanMasuk(data1[0].jamMasuk);
      setAturanPulang(data1[0].jamKeluar);
      setJarakMasuk(data1[0].jarakMasuk);
      setJarakPulang(data1[0].jarakKeluar);
    } catch (error) {
      console.error(error);
    }
  };

  const cekLibur = async (tanggal) => {
    var data1 = [];

    try {
      const response = await axios.get(GET_DAFTARHADIR_LIBUR + `${tanggal}`);
      data1 = response.data.data;

      if (data1.length > 0) {
        setHariLibur(data1[0].libur);
        setLibur(true);
      } else {
        setLibur(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Smartservice

  const getTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;

    let hariIniKu = dateTime(date);

    var indexHari = date.getDay();

    ambilStatusPD(unitKerja, indexHari, tanggalKu);

    sethariIni(hariIniKu);
    setLabelBulan(month);
    setTahun(year);

    setBulan(month);
    setTanggal(tanggalKu);
  };

  //auth

  const getTime = () => {
    const data = getTimeKu();
    setDateTimeKu(data.datetime);
  };
  useEffect(() => {
    if (dateTimeKu) {
      getTanggal(new Date(dateTimeKu));
    }
  }, [dateTimeKu]);

  //edit masuk

  //catat masuk

  const catatMasuk = (month, day, year, waktuAbsen, tanggalDH) => {
    var wM = "";
    if (waktuMasukKu) {
      wM = waktuMasukKu;
      wM = wM.split(":");
      wM = wM[0] + ":" + wM[1];
    }

    var wP = "";

    axios({
      method: "post",
      url: API_DAFTARHADIR,
      timeout: API_TIMEOUT,
      data: {
        tahun: year,
        bulan: month,
        tanggal: tanggalDH,
        nama: nama,
        opd: unitKerja,
        waktuMasuk: waktuAbsen,
        waktuPulang: wP,
        status: status,
        koordinatMasuk: latitude + "," + longitude,
        koordinatPulang: "",
        workingHours: "",
        token: "",
        idUser: uid,
        statusAdmin: true,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("error");
        } else {
          //BERHASIL

          var dataKirim = {
            id: response.data.data.insertId,
            status: status,
          };

          swal(
            "Success",
            "Soda Molek " +
              nama +
              " Pencatatan kehadiran berhasil dilakukan dengan status " +
              status +
              ". Terima kasih",
            "success"
          );
          history.push("/admin/daftarhadir");
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);

        //alert(error);
      });
  };

  const editDH = (year, month, day, waktuAbsenPulang, jamKerja) => {
    var wM = "";
    if (waktuMasukKu) {
      wM = waktuMasukKu;
      wM = wM.split(":");
      wM = wM[0] + ":" + wM[1];
    }

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/" + "pulang",
      timeout: API_TIMEOUT,
      data: {
        waktuMasuk: wM,
        waktuPulang: waktuAbsenPulang,
        status: status,
        koordinatPulang: latitude + "," + longitude,

        workingHours: jamKerja,

        id: idDH,
        statusAdmin: true,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("error");
        } else {
          //BERHASIL

          swal(
            "Success",
            "Soda Molek " +
              nama +
              " Pencatatan pulang berhasil dilakukan. Jam kerja anda adalah " +
              jamKerja +
              ". Terima kasih atas kontribusinya dalam pelaksanaan tugas hari ini",
            "success"
          );
          history.push("/admin/daftarhadir");
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);

        //alert(error);
      });
  };

  //catatMasuk

  //ceck catatan masuk

  const cekCatatanMasuk = (dataKirim) => {
    setSementaraLoading(true);
    axios({
      method: "post",
      url: API_DAFTARHADIR_CEK,
      timeout: API_TIMEOUT,
      data: {
        tanggal: dataKirim.tanggal,
        idUser: uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const cekCatatanResult =
            response.data.data.length > 0 ? response.data.data[0] : [];

          if (cekCatatanResult.id) {
            setStatus(cekCatatanResult.status);

            setWaktuMasukKu(cekCatatanResult.waktuMasuk);
            setWaktuPulang(cekCatatanResult.waktuPulang);
            setWorkingHours(cekCatatanResult.workingHours);
            setEdit(true);
            setIdDH(cekCatatanResult.id);

            if (
              cekCatatanResult.waktuPulang == "" &&
              cekCatatanResult.status == "Hadir"
            ) {
              setCatatMasukKu(false);
              setCatatPulangKu(true);
            } else {
              setCatatMasukKu(false);
              setCatatPulangKu(false);
            }
          } else {
            setCatatMasukKu(true);
            setCatatPulangKu(false);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });

    setSementaraLoading(false);
  };

  //aafadf

  const ambilStatusPD = async (unitKerja, indexHari, hariIni) => {
    var data2 = [];
    try {
      const response = await axios.get(
        GET_DAFTARHADIR_KOORDINATOPD + `${unitKerja}`
      );
      data2 = response.data.data;

      const koordinat = data2[0].koordinatKantor.split(",");
      const lat = parseFloat(koordinat[0]);
      const long = parseFloat(koordinat[1]);
      setcoordsKantor({
        lat: lat,
        long: long,
      });

      let aturan = "";
      const status = data2[0].hariKerja;

      if (status == 6) {
        aturan = "aturan6hari";

        if (indexHari == 0) {
          setLibur(true);
          setHariLibur("Weekend");
        } else {
          cekLibur(hariIni);
          ambilAturan(indexHari, aturan);
          var dataKirim = { tanggal: hariIni };

          cekCatatanMasuk(dataKirim);
        }
      } else {
        aturan = "aturan";
        //
        if (indexHari == 0 || indexHari == 6) {
          setLibur(true);
          setHariLibur("Weekend");
        } else {
          cekLibur(hariIni);
          ambilAturan(indexHari, aturan);
          var dataKirim = { tanggal: hariIni };

          cekCatatanMasuk(dataKirim);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    setUid(user.uid);
    setNama(user.nama);
    setAvatar(user.avatar);
    setUnitKerja(user.unitKerja);

    getTime();
  }, []);

  useEffect(() => {
    if (dateTimeKu) {
      getTanggal(new Date(dateTimeKu));
    }
  }, [dateTimeKu]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (status) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      verifikasi();
    } else {
      swal("Failed", "Maaf semua form wajib diisi", "error");
    }
    setSaveLoading(false);
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <Link to="/admin/daftarhadir" className="btn btn-primary">
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag="h4">{hariIni.tanggalLengkap}</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col>
                    <img
                      src={avatar}
                      alt={"avatar"}
                      style={{
                        width: 200,
                        height: 200,
                        borderRadius: 100,
                        borderWidth: 8,
                        borderColor: "red",
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama</label>
                      <Input type="text" value={nama} name="nama" />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Waktu Masuk</label>
                          <Input
                            disabled
                            type="time"
                            name="jenis"
                            step="1"
                            value={waktuMasukKu}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Waktu Pulang</label>
                          <Input
                            disabled
                            type="time"
                            name="harga"
                            step="1"
                            value={waktuPulang}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Jam Kerja</label>
                          <Input
                            disabled
                            type="text"
                            name="harga"
                            step="1"
                            value={workingHours}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label>Status Kehadiran</label>
                      <Input
                        disabled={catatMasukKu ? false : true}
                        type="select"
                        value={status}
                        name="jenis"
                        onChange={(event) => setStatus(event.target.value)}
                      >
                        <option value={""}>- Pilih- </option>
                        <option value={"Hadir"}>Hadir</option>
                        <option value={"Dinas Luar"}>Dinas Luar</option>
                        <option value={"Izin"}>Izin</option>
                        <option value={"Sakit"}>Sakit</option>
                        <option value={"Tugas Belajar"}>Tugas Belajar</option>
                        <option value={"Izin Belajar"}>Izin Belajar</option>
                        <option value={"Cuti"}>Cuti</option>
                      </Input>
                    </FormGroup>
                    <Col>
                      <Geolocation
                        render={({
                          fetchingPosition,
                          position: {
                            coords: { latitude, longitude } = {},
                          } = {},
                          error,
                          getCurrentPosition,
                        }) => {
                          setLatitude(-10.760178);
                          setLongitude(123.060573);

                          return (
                            <div>
                              {error && <div>{error.message}</div>}
                              <Row>
                                <Col> Coordinate</Col>
                              </Row>
                              <Row>
                                <Label>
                                  {latitude} , {longitude}
                                </Label>
                              </Row>
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Col>
                </Row>

                <Row>
                  {sementaraLoading ? (
                    <Button color="primary" type="submit" disabled>
                      <Spinner size="sm" color="light" /> Loading ...
                    </Button>
                  ) : (
                    <>
                      {catatMasukKu ? (
                        <Col>
                          {saveLoading ? (
                            <Button color="primary" type="submit" disabled>
                              <Spinner size="sm" color="light" /> Loading
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              color="danger"
                              className="ml-2"
                            >
                              <i className="nc-icon nc-basket"></i> Catat Masuk
                            </Button>
                          )}
                        </Col>
                      ) : (
                        <></>
                      )}
                      {catatPulangkuKu ? (
                        <Col>
                          {saveLoading ? (
                            <Button color="primary" type="submit" disabled>
                              <Spinner size="sm" color="light" /> Loading
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              color="danger"
                              className="ml-2"
                            >
                              <i className="nc-icon nc-basket"></i> Catat Pulang
                            </Button>
                          )}
                        </Col>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
