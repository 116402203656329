import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";

import { DELETE_EMERGENCY, GET_TOKEN, API_TIMEOUT, GET_INSTANSI } from "utils";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";

import { dateTime } from "utils";
import {
  getListDaftarHadir,
  getListDaftarHadirInstansi,
  getListDaftarHadirBulanan,
} from "actions/DaftarHadirAction";

export default function RekapOrang() {
  const { id } = useParams();
  const idKu = id.split(",");
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getListDHBulananLoading,
    getListDHBulananResult,
    getListDHBulananError,
  } = useSelector((state) => state.DaftarHadirReducer);

  // auth

  const [nama, setNama] = useState(idKu[4]);
  const [uid, setUid] = useState(idKu[3]);

  const [hariIni, sethariIni] = useState("");
  const [labelBulan, setLabelBulan] = useState("");
  const [bulan, setBulan] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [tahun, setTahun] = useState("");

  const [hadir, sethadir] = useState(0);
  const [dinasLuar, setDinasLuar] = useState(0);
  const [izin, setIzin] = useState(0);
  const [tugasBelajar, setTugasBelajar] = useState(0);
  const [sakit, setSakit] = useState(0);
  const [tanpaBerita, setTanpaBerita] = useState(0);
  const [kosong, setKosong] = useState(0);
  const [dateTimeKu, setDateTimeKu] = useState("");

  useEffect(() => {
    if (dateTimeKu) {
      getTanggal(new Date(dateTimeKu));
    }
  }, [dateTimeKu]);

  //auth

  //dari smart service

  const getTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = new Date().getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = new Date().getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;

    let hariIniKu = dateTime(new Date());

    sethariIni(hariIniKu);
    setLabelBulan(month);
    setTahun(year);

    if (month.length == 1) {
      month = "0" + month;
    }

    setBulan(month);

    if (day.length === 1) {
      day = "0" + day;
    }
    var tanggalKu = year + "-" + month + "-" + day;

    setTanggal(tanggalKu);
  };

  useEffect(() => {
    if (uid) {
      const data = { bulan: idKu[0], tahun: idKu[1], idUser: uid };
      dispatch(getListDaftarHadirBulanan(data));
    }
  }, []);

  //dari smart service

  useEffect(() => {
    setNama(idKu[4]);
    setUid(idKu[3]);
  }, []);

  return (
    <div className="content">
      <Row>
        <Col>
          <Link to="/admin/laporbulan" className="btn btn-primary">
            Kembali
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <CardTitle tag="h4">{nama}</CardTitle>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <CardTitle tag="h4">{idKu[2]}</CardTitle>
                </Col>
                <Col>
                  <ReactHTMLTableToExcel
                    className="btn"
                    table="dho"
                    filename={"export DHO " + tanggal}
                    sheet="Data "
                    buttonText="Export to Exl"
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ overflowX: "auto" }}>
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th>Id</th>
                    <th>Tanggal</th>
                    <th>Nama</th>
                    <th>Status</th>
                    <th>Waktu Masuk</th>
                    <th>Waktu Pulang</th>
                    <th>Jam Kerja</th>
                    <th>Bulan</th>
                  </tr>
                </thead>

                <tbody>
                  {getListDHBulananResult ? (
                    Object.keys(getListDHBulananResult).map((key, index) => (
                      <tr key={key}>
                        <td>{index + 1}</td>

                        <td>
                          {getListDHBulananResult[key].tanggal.split("-")[2] +
                            "-" +
                            getListDHBulananResult[key].tanggal.split("-")[1] +
                            "-" +
                            getListDHBulananResult[key].tanggal.split("-")[0]}
                        </td>

                        <td>{getListDHBulananResult[key].nama}</td>
                        <td>{getListDHBulananResult[key].status}</td>
                        <td>{getListDHBulananResult[key].waktuMasuk} </td>
                        <td>{getListDHBulananResult[key].waktuPulang} </td>
                        <td>{getListDHBulananResult[key].workingHours} </td>
                        <td>{idKu[2]} </td>
                      </tr>
                    ))
                  ) : getListDHBulananLoading ? (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  ) : getListDHBulananError ? (
                    <tr>
                      <td colSpan="6" align="center">
                        {getListDHBulananError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
