import swal from "sweetalert";
import FIREBASE from "../config/FIREBASE";
import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";

import { ADMINS_LOGIN, ADMINS_LOGOUT, API_TIMEOUT } from "../utils";
import axios from "axios";

export const LOGIN_USER = "LOGIN_USER";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const loginUser2 = (email, password) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGIN_USER);
    axios({
      method: "post",
      url: ADMINS_LOGIN,
      timeout: API_TIMEOUT,
      data: { username: email, password: password },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, LOGIN_USER, "error");
        } else {
          //BERHASIL
          if (response.data.success == 0) {
            swal("Failed", "email dan password anda salah", "error");
            dispatchError(dispatch, LOGIN_USER, "error");
          } else {
            dispatchSuccess(
              dispatch,
              LOGIN_USER,
              response.data.data ? response.data.data : []
            );
          }
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, LOGIN_USER, error);
        swal("Failed", "Anda Bukan Admin", "error");
      });
  };
};

export const loginUser = (email, password) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGIN_USER);
    FIREBASE.auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        FIREBASE.database()
          .ref(`users/${res.user.uid}`)
          .once("value")
          .then((resDB) => {
            // Signed in
            if (resDB.val()) {
              if (resDB.val().statusRegister !== "ASN") {
                dispatchError(dispatch, LOGIN_USER, "Forbiden");
                swal(
                  "Failed",
                  "Anda tidak memiliki akses ke Smart DHO",
                  "error"
                );
                return;
              }

              // if (resDB.val().dho === "admin" || resDB.val().dho === "iphone") {
              //   window.localStorage.setItem(
              //     "user",
              //     JSON.stringify(resDB.val())
              //   );
              //   dispatchSuccess(dispatch, LOGIN_USER, resDB.val());
              // } else {

              axios({
                method: "post",
                url: "https://smartdh.rotendaokab.go.id/api/admin/login",
                timeout: API_TIMEOUT,
                data: { username: resDB.val().email, uid: resDB.val().uid },
              })
                .then((response) => {
                  if (response.status !== 200) {
                    // ERROR
                    dispatchError(dispatch, LOGIN_USER, "Forbiden");
                    swal("Failed", "Forbiden", "error");
                  } else {
                    //BERHASIL

                    // console.log(response.data);

                    if (response.data.accessToken) {
                      const data = {
                        username: response.data.username,
                        nama: response.data.nama,
                        unitKerja: response.data.perangkatdaerah,
                        namaPengguna: resDB.val().namaPengguna,
                        uid: resDB.val().uid,
                        avatar: resDB.val().avatar,
                        status: response.data.status,
                      };

                      window.localStorage.setItem("user", JSON.stringify(data));
                      dispatchSuccess(dispatch, LOGIN_USER, data);

                      swal(
                        "Success",
                        "Selamat datang " +
                          response.data.nama +
                          ". Anda login sebagai " +
                          response.data.status,
                        "success"
                      );
                    } else {
                      if (
                        resDB.val().dho === "admin" ||
                        resDB.val().dho === "iphone"
                      ) {
                        const data = {
                          username: resDB.val().email,
                          nama: resDB.val().nama,
                          namaPengguna: resDB.val().namaPengguna,
                          unitKerja: resDB.val().unitKerja,
                          uid: resDB.val().uid,
                          avatar: resDB.val().avatar,
                          status: resDB.val().dho,
                        };

                        window.localStorage.setItem(
                          "user",
                          JSON.stringify(data)
                        );
                        dispatchSuccess(dispatch, LOGIN_USER, data);
                        swal(
                          "Success",
                          "Selamat datang " +
                            resDB.val().nama +
                            ". Anda login sebagai " +
                            resDB.val().dho,
                          "success"
                        );
                      } else {
                        const data = {
                          username: resDB.val().email,
                          nama: resDB.val().nama,
                          unitKerja: resDB.val().unitKerja,
                          namaPengguna: resDB.val().namaPengguna,
                          uid: resDB.val().uid,
                          avatar: resDB.val().avatar,
                          status: "user",
                        };

                        window.localStorage.setItem(
                          "user",
                          JSON.stringify(data)
                        );

                        dispatchSuccess(dispatch, LOGIN_USER, data);
                        swal(
                          "Success",
                          "Selamat datang " +
                            resDB.val().nama +
                            ". Anda login sebagai " +
                            "user",
                          "success"
                        );
                      }
                    }
                  }
                })
                .catch((error) => {
                  // ERROR
                  dispatchError(dispatch, LOGIN_USER, "Forbiden");
                  swal("Failed", "Forbiden", "error");
                });
            }

            // } else {
            //   dispatchError(dispatch, LOGIN_USER, "Anda Bukan Admin");
            //   swal("Failed", "Anda Bukan Admin", "error");
            //   //}
            // }
          })
          .catch((error) => {
            dispatchError(dispatch, LOGIN_USER, error);
            swal("Failed", error, "error");
          });
      })
      .catch((error) => {
        var errorMessage = error.message;
        dispatchError(dispatch, LOGIN_USER, errorMessage);
        swal("Failed", errorMessage, "error");
      });
  };
};

export const checkLogin = (history) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CHECK_LOGIN);

    if (window.localStorage.getItem("user")) {
      const user = JSON.parse(window.localStorage.getItem("user"));

      FIREBASE.database()
        .ref(`users/${user.uid}`)
        .once("value")
        .then((resDB) => {
          if (resDB.val()) {
            // if (resDB.val().status === 'admin') {
            dispatchSuccess(dispatch, CHECK_LOGIN, user);
            //  } else {
            //  dispatchError(dispatch, CHECK_LOGIN, 'Anda Bukan Admin');
            //history.push({ pathname: '/login' });
            // }
          } else {
            dispatchError(dispatch, CHECK_LOGIN, "Anda Bukan Admin");
            history.push({ pathname: "/login" });
          }
        })
        .catch((error) => {
          dispatchError(dispatch, CHECK_LOGIN, error);
          history.push({ pathname: "/login" });
        });
    } else {
      dispatchError(dispatch, CHECK_LOGIN, "Belum Login");
      history.push({ pathname: "/login" });
    }
  };
};

export const logOutUser2 = (history) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGOUT);

    axios({
      method: "delete",
      url: ADMINS_LOGOUT,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, LOGOUT, "error");
          history.push("/");
        } else {
          //BERHASIL
          if (response.data.success == 0) {
            swal("Failed", "email dan password anda salah", "error");
            dispatchError(dispatch, LOGOUT, "error");
            history.push("/");
          } else {
            dispatchSuccess(
              dispatch,
              LOGOUT,
              response.data.data ? response.data.data : []
            );
            history.push("/");
          }
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, LOGOUT, error);
        history.push("/");
        swal("Failed", "Anda Bukan Admin", "error");
      });

    // FIREBASE.auth()
    //   .signOut()
    //   .then((res) => {
    //     window.localStorage.removeItem('user');
    //     dispatchSuccess(dispatch, LOGOUT, res);
    //     history.push({ pathname: '/login' });
    //   })
    //   .catch((error) => {
    //     dispatchError(dispatch, LOGOUT, error.message);
    //     swal('Failed!', error.message, 'error');
    //   });
  };
};

export const logOutUser = (history) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGOUT);

    FIREBASE.auth()
      .signOut()
      .then((res) => {
        window.localStorage.removeItem("user");
        dispatchSuccess(dispatch, LOGOUT, res);
        history.push({ pathname: "/login" });
      })
      .catch((error) => {
        dispatchError(dispatch, LOGOUT, error.message);
        swal("Failed!", error.message, "error");
      });
  };
};
const updateUserDB = async (data) => {
  axios({
    method: "patch",
    url:
      "https://smartservice.rotendaokab.go.id/api/admin/update/smartuser/" +
      data.uid,
    timeout: API_TIMEOUT,
    data: {
      unitKerja: data.unitKerja,
      statuspegawai: data.statuspegawai,
      statusDHO: data.statusDHO,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        // ERROR
      } else {
        //BERHASIL
      }
    })
    .catch((error) => {
      // ERROR

      alert(error);
    });
};

export const updateProfile = (data, setSaveLoading) => {
  return (dispatch) => {
    //LOADING
    let dataBaru = {};
    if (data.statusRegister == "ASN") {
      dataBaru = {
        uid: data.uid,
        nama: data.nama,
        alamat: data.alamat,
        nohp: data.nohp,
        kecamatan: data.kecamatan,
        desa: data.desa,
        email: data.email,
        pangkat: data.pangkat,
        unitKerja: data.unitKerja,
        nip: data.nip,
        status: data.status,
        avatar: data.avatar,
        domisili: data.domisili,
        statusRegister: data.statusRegister,
        provinsi: data.provinsi,
        kota: data.kota,
        dob: data.dob,
        date: data.date,
        gender: data.gender,
        fcmToken: data.fcmToken,
        token: data.token,
        nik: data.nik,
        namaPengguna: data.namaPengguna,
        dho: data.dho,
        statuspegawai: data.statuspegawai,
        statusDHO: data.statusDHO,
      };
    } else {
      dataBaru = {
        uid: data.uid,
        nama: data.nama,
        alamat: data.alamat,
        nohp: data.nohp,
        kecamatan: data.kecamatan,
        desa: data.desa,
        email: data.email,
        status: data.status,
        avatar: data.avatar,
        domisili: data.domisili,
        statusRegister: data.statusRegister,
        provinsi: data.provinsi,
        kota: data.kota,
        dob: data.dob,
        date: data.date,
        gender: data.gender,
        fcmToken: data.fcmToken,
        token: data.token,
        nik: data.nik,
        namaPengguna: data.namaPengguna,
      };
    }

    dispatchLoading(dispatch, UPDATE_PROFILE);

    FIREBASE.database()
      .ref("users/" + dataBaru.uid)
      .update(dataBaru)
      .then((response) => {
        //SUKSES
        dispatchSuccess(dispatch, UPDATE_PROFILE, response ? response : []);
        //simpan ke local storage (Async)
        const dataku = {
          unitKerja: dataBaru.unitKerja,
          statuspegawai: dataBaru.statuspegawai,
          uid: dataBaru.uid,
          statusDHO: dataBaru.statusDHO,
        };
        updateUserDB(dataku);

        setSaveLoading(false);
        swal("Success!", "Data Profile berhasil diedit", "success");
      })
      .catch((error) => {
        //ERROR;
        dispatchError(dispatch, UPDATE_PROFILE, error.message);
        setSaveLoading(false);
        // console.log(error);

        swal("Failed!", error.message, "error");
      });
  };
};
