import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
  Alert,
  Progress,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";

import { GET_TOKEN, API_TIMEOUT } from "utils";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";

import { dateTime } from "utils";
import { convertToMinutes } from "utils";

export default function DetailTPP() {
  const { id } = useParams();
  const idKu = id.split(",,");
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getListDHBulananLoading,
    getListDHBulananResult,
    getListDHBulananError,
  } = useSelector((state) => state.DaftarHadirReducer);

  // auth

  const [nama, setNama] = useState(idKu[3]);
  const [uid, setUid] = useState(idKu[2]);

  const [hariIni, sethariIni] = useState("");
  const [labelBulan, setLabelBulan] = useState("");
  const [bulan, setBulan] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [tahun, setTahun] = useState("");
  const [dataKu, setDataKu] = useState();
  const [result, setResult] = useState();

  const [hariEfektif, setHariEfektif] = useState(0);

  const [totalJamKerja, setTotalJamKerja] = useState(0);
  const [potongan, setPotongan] = useState(0);

  const [dateTimeKu, setDateTimeKu] = useState("");

  const [listLibur, setListLibur] = useState([]);

  useEffect(() => {
    if (dateTimeKu) {
      getTanggal(new Date(dateTimeKu));
    }
  }, [dateTimeKu]);

  //auth

  //dari smart service

  function formatDate(inputDateStr) {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const dateObj = new Date(inputDateStr);

    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const getTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = new Date().getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = new Date().getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;

    let hariIniKu = dateTime(new Date());

    sethariIni(hariIniKu);
    setLabelBulan(month);
    setTahun(year);

    if (month.length == 1) {
      month = "0" + month;
    }

    setBulan(month);

    if (day.length === 1) {
      day = "0" + day;
    }
    var tanggalKu = year + "-" + month + "-" + day;

    setTanggal(tanggalKu);
  };

  useEffect(() => {
    if (uid) {
      const data = { tanggalawal: idKu[0], tanggalakhir: idKu[1], uid: uid };

      // const d = getDatesWithoutWeekends(idKu[0], idKu[1]);

      getDetailTPP(data);
    }
  }, []);

  const getDetailTPP = (data) => {
    axios({
      method: "post",
      url: "https://smartdh.rotendaokab.go.id/api/unitkerja/dho/tpp/person",
      timeout: API_TIMEOUT,
      data: data,
      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("errorr");
        } else {
          //BERHASIL
          const hasil = response.data.data;
          setDataKu(hasil);
          setHariEfektif(hasil.length);
          let total = 0;
          let pot = 0;
          if (hasil.length > 0) {
            hasil.map((item) => {
              total = total + item.total_Jam_Kerja;
              pot = pot + item.potongan;
            });
          }
          setPotongan(pot);
          setTotalJamKerja(total);
        }
      })
      .catch((error) => {
        // ERROR

        alert("error");
      });
  };

  //dari smart service

  useEffect(() => {
    setNama(idKu[3]);
    setUid(idKu[2]);
  }, []);

  return (
    <div className="content">
      <Row>
        {" "}
        <Col>
          <Link to="/admin/tpp/asn" className="btn btn-primary">
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-globe text-warning" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Hari Kerja</p>
                    <CardTitle tag="p">{hariEfektif}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="fas fa-sync-alt" /> hari
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins text-success" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Total Jam Kerja</p>
                    <CardTitle tag="p">{totalJamKerja} </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-calendar" /> {"Jam"}
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-vector text-danger" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Potongan</p>
                    <CardTitle tag="p">{potongan}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> Jam
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="12" xs="5">
                  <div className={`d-flex justify-content-between`}>
                    <div className="d-flex flex-column mt-3">
                      <div>
                        <p className="body-2">{100 - potongan}% Permormance </p>
                      </div>
                      <Progress
                        color={100 - potongan > 75 ? "success" : "danger"}
                        className="progress-xs"
                        value={100 - potongan}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> Kinerja
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <CardTitle tag="h4">{nama}</CardTitle>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <CardTitle tag="h6">
                    {formatDate(idKu[0]) + " sd " + formatDate(idKu[1])}
                  </CardTitle>
                </Col>
                <Col>
                  <ReactHTMLTableToExcel
                    className="btn"
                    table="dhodetail"
                    filename={"Detail DHO " + nama + " - " + new Date()}
                    sheet="Detail"
                    buttonText="Export to Exl"
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ overflowX: "auto" }}>
              <Table id="dhodetail">
                <thead className="text-primary">
                  <tr>
                    <th>Id</th>
                    <th>Tanggal</th>
                    <th>Nama</th>
                    <th>Status</th>
                    <th>Waktu Masuk</th>
                    <th>Waktu Pulang</th>
                    <th>Waktu Kerja (Menit)</th>
                    <th>Selisih (Menit)</th>
                    <th>Potongan (%)</th>
                  </tr>
                </thead>

                <tbody>
                  {dataKu ? (
                    Object.keys(dataKu).map((key, index) => (
                      <tr key={key}>
                        <td>{index + 1}</td>

                        <td>{formatDate(dataKu[key].tanggal)}</td>

                        <td>{dataKu[key].nama}</td>
                        <td>
                          {dataKu[key].status
                            ? dataKu[key].status
                            : "Tidak Mengisi DHO"}
                        </td>
                        <td>{dataKu[key].waktuMasuk} </td>
                        <td>{dataKu[key].waktuPulang} </td>
                        <td>{dataKu[key].total_Jam_Kerja} </td>

                        <td>
                          {dataKu[key].total_Jam_Kerja -
                            dataKu[key].jamkerja_menit}{" "}
                        </td>
                        <td>{dataKu[key].potongan} </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
