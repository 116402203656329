import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
  Progress,
  Badge,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { API_TIMEOUT } from "utils";

import axios from "axios";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

import {
  getDatesWithoutWeekends,
  hitungtotaljamkerja,
  convertDecimalHoursToTime,
} from "utils";
import { GET_LIBUR } from "utils";
import { convertToMinutes } from "utils";
import { convertFromMinutes } from "utils";
import Avatar from "../../assets/img/default-avatar.png";
import { GET_INSTANSI } from "utils";
import swal from "sweetalert";
import { getTimeKu } from "utils";

export default function TPPAsn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [listInstansi, setListInstansi] = useState([]);

  // auth

  const [labelBulan, setLabelBulan] = useState("");
  const [bulan, setBulan] = useState("");

  const [instansi, setInstansi] = useState("");
  const [listLibur, setListLibur] = useState([]);
  const [totalHK, setTotalHK] = useState(0);
  const [totalWH, setTotalWH] = useState("0:00");
  const [rerataWH, setRerataWH] = useState(0);

  const [kehadiran, setKehadiran] = useState(0);
  const [jumlahAsn, setJumlahAsn] = useState(0);

  const [listLaporPD, setListLaporPD] = useState();
  const [hariEfektif, setHariEfektif] = useState("");

  const ambilTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;
    return tanggalKu;
  };

  const ambilTanggalAwal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + "01";
    return tanggalKu;
  };

  const [tanggalAwal, setTanggalAwal] = useState(() =>
    ambilTanggalAwal(new Date())
  );

  const [tanggalAkhir, setTanggalAkhir] = useState(() =>
    ambilTanggal(new Date())
  );

  const [dateTimeKu, setDateTimeKu] = useState("");
  const labelBulanKu = {
    0: "Januari",
    1: "Februari",
    2: "Maret",
    3: "April",
    4: "Mei",
    5: "Juni",
    6: "Juli",
    7: "Agustus",
    8: "September",
    9: "Oktober",
    10: "November",
    11: "Desember",
  };

  const getTime = () => {
    const data = getTimeKu();
    setDateTimeKu(data.datetime);
  };
  useEffect(() => {
    getListInstansi();
  }, []);

  useEffect(() => {
    if (tanggalAwal && tanggalAkhir && instansi) {
      loadingData();
    }
  }, [instansi]);

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    setInstansi(user.unitKerja);
  }, []);

  //auth

  //dari smart service

  function urutkanBerdasarkanPotongan(array) {
    return array.sort((a, b) => a.potongan - b.potongan);
  }

  const getLaporanPD = (data) => {
    axios({
      method: "post",
      url: "https://smartdh.rotendaokab.go.id/api/unitkerja/dho/tpp",
      timeout: API_TIMEOUT,
      data: data,

      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("errorr");
        } else {
          //BERHASIL

          const result = response.data.data;
          let resultSort = [];
          if (result.length > 0) {
            resultSort = urutkanBerdasarkanPotongan(result);
          }
          setListLaporPD(resultSort);
        }
      })
      .catch((error) => {
        // ERROR
        setListLaporPD([]);
        swal("error", "Ada kesahalan yang terjadi ", "error");
      });
  };

  //dari smart service

  useEffect(() => {
    getTime();
  }, []);

  const loadingData = () => {
    setListLaporPD();
    const data2 = {
      tanggalawal: tanggalAwal,
      tanggalakhir: tanggalAkhir,
      unitkerja: instansi,
    };
    setLabelBulan(labelBulanKu[parseInt(bulan) - 1]);
    getLaporanPD(data2);
  };

  useEffect(() => {
    if (listLibur) {
      const d = getDatesWithoutWeekends(tanggalAwal, tanggalAkhir, listLibur);
      const wh = hitungtotaljamkerja(d);
      setTotalHK(d.length);
      setTotalWH(wh);
    }
  }, [listLibur]);

  return (
    <div className="content">
      <Row>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-globe text-warning" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Hari Kerja</p>
                    <CardTitle tag="p">
                      {hariEfektif && hariEfektif.hariEfektif5.length}
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="fas fa-sync-alt" /> hari
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins text-success" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Jumlah ASN</p>
                    <CardTitle tag="p">{jumlahAsn} </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-calendar" /> {"orang"}
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-vector text-danger" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Rerata Kehadiran</p>
                    <CardTitle tag="p">{kehadiran}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> orang
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="12" xs="5">
                  <div className={`d-flex justify-content-between`}>
                    <div className="d-flex flex-column mt-3">
                      <div>
                        <p className="body-2">{kehadiran * 100}% Kehadiran </p>
                      </div>
                      <Progress
                        color={
                          (kehadiran / jumlahAsn) * 100 > 50
                            ? "success"
                            : "danger"
                        }
                        className="progress-xs"
                        value={(kehadiran / jumlahAsn) * 100}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> Kinerja
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">
                    {"Perangkat Daerah kab. Rote Ndao"}
                  </CardTitle>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <FormGroup>
                    <label>Tanggal awal</label>
                    <Input
                      type="date"
                      value={tanggalAwal}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const awal = ambilTanggal(date);

                        setTanggalAwal(awal);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Tanggal akhir</label>
                    <Input
                      type="date"
                      value={tanggalAkhir}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const akhir = ambilTanggal(date);

                        setTanggalAkhir(akhir);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <Button onClick={loadingData}>Load</Button>
                </Col>
                <Col>
                  <Col md={3}>
                    <ReactHTMLTableToExcel
                      className="btn"
                      table="laporBulan"
                      filename={
                        "Rekap DHO " +
                        tanggalAwal +
                        " - " +
                        tanggalAkhir +
                        " " +
                        "Perangkat Daerah" +
                        new Date()
                      }
                      sheet="Data "
                      buttonText="Export to Exl"
                    />
                  </Col>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ overflowY: "auto" }}>
              <Table id="laporBulan">
                <thead className="text-primary">
                  <tr>
                    <th>No</th>
                    <th>Nama</th>

                    <th>Hari Kerja</th>
                    <th>Jumlah Waktu Kerja wajib (menit) </th>
                    <th>Jumlah Waktu Kerja yg dicapai (menit) </th>
                    <th>potongan (%) </th>
                    <th>
                      Hadir{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Dinas Luar{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Izin{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Sakit{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Cuti{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Tugas Belajar{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>

                    <th>
                      Izin Belajar{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Tidak Mengisi{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th className="text-center" style={{ width: 150 }}>
                      Performance
                    </th>

                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {listLaporPD ? (
                    listLaporPD.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{item.nama}</td>
                        <td>{item.jumlahHari}</td>
                        <td>{item.jamKerjaWajib}</td>
                        <td>{item.jamKerjaCapai}</td>
                        <td>{item.potongan}</td>

                        <td>{item.hadir} </td>
                        <td>{item.dinasLuar} </td>
                        <td>{item.izin} </td>
                        <td>{item.sakit} </td>
                        <td>{item.cuti} </td>
                        <td>{item.tugasBelajar} </td>
                        <td>{item.izinBelajar} </td>
                        <td>{item.tidakMengisi} </td>

                        <td className="text-center" style={{ width: 150 }}>
                          {" "}
                          <div className={`d-flex justify-content-between`}>
                            <div className="d-flex flex-column mt-3">
                              <div>
                                <p className="body-2">
                                  {Math.round(100 - item.potongan)}% Disiplin
                                  Kerja{" "}
                                </p>
                              </div>
                              <Progress
                                color={
                                  Math.round(100 - item.potongan) > 75
                                    ? "success"
                                    : "danger"
                                }
                                className="progress-xs"
                                value={Math.round(100 - item.potongan)}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <Link
                            className="btn btn-warning"
                            to={
                              "/admin/detailtpp/" +
                              tanggalAwal +
                              ",," +
                              tanggalAkhir +
                              ",," +
                              item.uid
                            }
                          >
                            <i className="nc-icon nc-ruler-pencil"></i> Detail
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
