import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
  Progress,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { API_TIMEOUT } from "utils";

import swal from "sweetalert";
import axios from "axios";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { sortDataByUnitKerja } from "utils";
import { getTimeKu } from "utils";

export default function LaporHariPD() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getListDHLoading, getListDHResult, getListDHError } = useSelector(
    (state) => state.DaftarHadirReducer
  );

  // auth

  const [instansi, setInstansi] = useState("");

  const [totalASN, setTotalASN] = useState(0);
  const [totalHadir, setTotalHadir] = useState(0);

  const [listPDHari, setListPDHari] = useState();

  const ambilTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;
    return tanggalKu;
  };

  const ambilTanggalAwal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;
    return tanggalKu;
  };

  const [tanggalAwal, setTanggalAwal] = useState(() =>
    ambilTanggalAwal(new Date())
  );

  const [dateTimeKu, setDateTimeKu] = useState("");

  const getTime = () => {
    const data = getTimeKu();
    setDateTimeKu(data.datetime);
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    setInstansi(user.unitKerja);
    const data = { tanggal: tanggalAwal };
    getHariPD(data);
  }, []);

  //auth

  //dari smart service

  const getHariPD = (data) => {
    axios({
      method: "post",
      url: "https://smartservice.rotendaokab.go.id/api/admin/unitkerja/dho",
      timeout: API_TIMEOUT,
      data: data,
      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("errorr");
        } else {
          //BERHASIL
          const result = response.data.data;
          const data = result.filter((item) => {
            return (
              item.posisi == 0 &&
              item.unitKerja !== "DINAS KETAHANAN PANGAN" &&
              item.unitKerja !==
                "SEKRETARIAT DEWAN PENGURUS KORPS PEGAWAI REPUBLIK INDONESIA"
            );
          });

          let totASN = 0;
          let totHadir = 0;

          data.map((item) => {
            totASN = item.jumlah_asn + totASN;
            totHadir = item.Hadir + item.Dinas_Luar + totHadir;
          });

          const sortUK = urutkanArray(data);

          setTotalASN(totASN);
          setTotalHadir(totHadir);
          setListPDHari(sortUK);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error.message);
      });
  };

  //dari smart service

  function urutkanArray(objects) {
    // Menggunakan metode sort untuk mengurutkan array
    objects.sort(function (a, b) {
      // Menghitung nilai untuk perbandingan
      const nilaiA =
        ((a.Hadir +
          a.Dinas_Luar +
          a.Tugas_Belajar +
          a.Izin_Belajar +
          a.Cuti +
          a.Izin +
          a.Sakit) /
          a.jumlah_asn) *
        100;
      const nilaiB =
        ((b.Hadir +
          b.Dinas_Luar +
          b.Tugas_Belajar +
          b.Izin_Belajar +
          b.Cuti +
          b.Izin +
          b.Sakit) /
          b.jumlah_asn) *
        100;

      // Mengurutkan secara descending (terbesar sampai terkecil)
      return nilaiB - nilaiA;
    });

    return objects;
  }

  useEffect(() => {
    getTime();
  }, []);

  const loadingData = () => {
    setListPDHari();
    const data2 = {
      tanggal: tanggalAwal,
    };
    getHariPD(data2);
  };

  return (
    <div className="content">
      <Row>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-globe text-warning" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Total PD</p>
                    <CardTitle tag="p">
                      {listPDHari && listPDHari.length}
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="fas fa-sync-alt" /> Perangkat Daerah
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins text-success" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Total ASN</p>
                    <CardTitle tag="p">{totalASN} </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-calendar" /> {"Orang"}
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-vector text-danger" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Total hadir</p>
                    <CardTitle tag="p">{totalHadir}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> orang
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="12" xs="5">
                  <div className={`d-flex justify-content-between`}>
                    <div className="d-flex flex-column mt-3">
                      <div>
                        <p className="body-2">
                          {((totalHadir / totalASN) * 100).toFixed(0)}%
                          kehadiran{" "}
                        </p>
                      </div>
                      <Progress
                        color={
                          (totalHadir / totalASN) * 100 > 50
                            ? "success"
                            : "danger"
                        }
                        className="progress-xs"
                        value={(totalHadir / totalASN) * 100}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> Kehadiran
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">
                    {"Perangkat Daerah Kab. Rote Ndao"}
                  </CardTitle>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <FormGroup>
                    <label>Tanggal awal</label>
                    <Input
                      type="date"
                      value={tanggalAwal}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const awal = ambilTanggal(date);

                        setTanggalAwal(awal);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <Button color="success" onClick={loadingData}>
                    Load
                  </Button>
                </Col>
                <Col>
                  <Col md={3}>
                    <ReactHTMLTableToExcel
                      className="btn"
                      table="laporBulan"
                      filename={"Rekap DHO " + tanggalAwal + new Date()}
                      sheet="Data "
                      buttonText="Export to Exl"
                    />
                  </Col>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ overflowY: "auto" }}>
              <Table id="laporBulan">
                <thead className="text-primary">
                  <tr>
                    <th>No</th>
                    <th>Perangkat Daerah</th>

                    <th>Jumlah ASN</th>
                    <th>Hadir </th>
                    <th>Dinas Luar</th>
                    <th>Izin</th>
                    <th>Sakit</th>
                    <th>Cuti</th>
                    <th>Tugas Belajar</th>
                    <th>Izin Belajar</th>
                    <th>Tanpa Berita</th>
                    <th>Performance</th>

                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {listPDHari ? (
                    listPDHari.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{item.unitKerja}</td>

                        <td>{item.jumlah_asn}</td>
                        <td>{item.Hadir} </td>
                        <td>{item.Dinas_Luar} </td>
                        <td>{item.Izin} </td>
                        <td>{item.Sakit} </td>
                        <td>{item.Cuti} </td>
                        <td>{item.Tugas_Belajar} </td>
                        <td>{item.Izin_Belajar} </td>
                        <td>
                          {item.jumlah_asn -
                            (item.Hadir +
                              item.Dinas_Luar +
                              item.Tugas_Belajar +
                              item.Izin_Belajar +
                              item.Izin +
                              item.Cuti +
                              item.Sakit) <
                          0
                            ? 0
                            : item.jumlah_asn -
                              (item.Hadir +
                                item.Dinas_Luar +
                                item.Tugas_Belajar +
                                item.Izin_Belajar +
                                item.Izin +
                                item.Cuti +
                                item.Sakit)}
                        </td>
                        <td className="text-center" style={{ width: 150 }}>
                          <p className="body-2">
                            {item.jumlah_asn
                              ? Math.round(
                                  ((item.Hadir +
                                    item.Dinas_Luar +
                                    item.Tugas_Belajar +
                                    item.Izin_Belajar +
                                    item.Izin +
                                    item.Cuti +
                                    item.Sakit) /
                                    item.jumlah_asn) *
                                    100
                                ) > 100
                                ? 100
                                : Math.round(
                                    ((item.Hadir +
                                      item.Dinas_Luar +
                                      item.Tugas_Belajar +
                                      item.Izin_Belajar +
                                      item.Izin +
                                      item.Cuti +
                                      item.Sakit) /
                                      item.jumlah_asn) *
                                      100
                                  )
                              : 0}
                          </p>
                        </td>

                        <td>
                          <Link
                            className="btn btn-warning"
                            to={
                              "/admin/perangkatdaerah/detail/" +
                              tanggalAwal +
                              ",," +
                              item.unitKerja
                            }
                          >
                            <i className="nc-icon nc-ruler-pencil"></i> Detail
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
