import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
  Progress,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";

import { API_TIMEOUT } from "utils";

import axios from "axios";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getListDaftarHadirInstansiRekapMinggu } from "actions/DaftarHadirAction";

import {
  getDatesWithoutWeekends,
  hitungtotaljamkerja,
  convertDecimalHoursToTime,
} from "utils";
import { GET_LIBUR } from "utils";
import { convertToMinutes } from "utils";
import { convertFromMinutes } from "utils";
import Avatar from "../../assets/img/default-avatar.png";
import { getListDaftarHadirInstansiReguler } from "actions/DaftarHadirAction";
import { getTimeKu } from "utils";

export default function KinerjaPD() {
  const { id } = useParams();
  const idKu = id.split(",,");

  const dispatch = useDispatch();
  const history = useHistory();

  const { getListDHLoading, getListDHResult, getListDHError } = useSelector(
    (state) => state.DaftarHadirReducer
  );

  // auth

  const [labelBulan, setLabelBulan] = useState("");
  const [bulan, setBulan] = useState("");

  const [instansi, setInstansi] = useState(idKu[2]);

  const [listLibur, setListLibur] = useState([]);
  const [totalHK, setTotalHK] = useState(0);
  const [totalWH, setTotalWH] = useState("0:00");
  const [rerataWH, setRerataWH] = useState(0);
  const ambilTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;
    return tanggalKu;
  };

  const ambilTanggalAwal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;
    return tanggalKu;
  };

  const [tanggalAwal, setTanggalAwal] = useState(() =>
    ambilTanggalAwal(new Date(idKu[0]))
  );

  const [tanggalAkhir, setTanggalAkhir] = useState(() =>
    ambilTanggal(new Date(idKu[1]))
  );

  const [dateTimeKu, setDateTimeKu] = useState("");
  const labelBulanKu = {
    0: "Januari",
    1: "Februari",
    2: "Maret",
    3: "April",
    4: "Mei",
    5: "Juni",
    6: "Juli",
    7: "Agustus",
    8: "September",
    9: "Oktober",
    10: "November",
    11: "Desember",
  };

  const getTime = () => {
    const data = getTimeKu();
    setDateTimeKu(data.datetime);
  };

  useEffect(() => {
    if (instansi) {
      loadingData();
    }
  }, [instansi]);

  useEffect(() => {
    if (getListDHResult && totalWH) {
      let rr = 0;
      getListDHResult.map((item) => {
        rr = rr + convertToMinutes(item.workingHours);
      });
      const avr = rr / getListDHResult.length;
      setRerataWH(avr);
    }
  }, [getListDHResult, totalWH]);

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    setInstansi(idKu[2]);
  }, []);

  //auth

  //dari smart service

  const getLibur = () => {
    axios({
      method: "get",
      url: GET_LIBUR,
      timeout: API_TIMEOUT,

      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("errorr");
        } else {
          //BERHASIL

          setListLibur(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert("error");
      });
  };

  //dari smart service

  useEffect(() => {
    getTime();
  }, []);

  const loadingData = () => {
    const data2 = {
      tAwal: tanggalAwal,
      tAkhir: tanggalAkhir,
      unitKerja: instansi,
    };
    setLabelBulan(labelBulanKu[parseInt(bulan) - 1]);
    dispatch(getListDaftarHadirInstansiReguler(data2));
    getLibur();
  };

  useEffect(() => {
    if (listLibur) {
      const d = getDatesWithoutWeekends(tanggalAwal, tanggalAkhir, listLibur);
      const wh = hitungtotaljamkerja(d);
      setTotalHK(d.length);
      setTotalWH(wh);
    }
  }, [listLibur]);

  return (
    <div className="content">
      <Row>
        {" "}
        <Col>
          <Link to="/admin/laporanPD" className="btn btn-primary">
            Kembali
          </Link>
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-globe text-warning" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Hari Kerja</p>
                    <CardTitle tag="p">{totalHK}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="fas fa-sync-alt" /> hari
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins text-success" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Total Jam Kerja</p>
                    <CardTitle tag="p">{totalWH} </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-calendar" /> {"Jam"}
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-vector text-danger" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Capain Rata - rata</p>
                    <CardTitle tag="p">
                      {convertFromMinutes(rerataWH)}
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> Jam
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="12" xs="5">
                  <div className={`d-flex justify-content-between`}>
                    <div className="d-flex flex-column mt-3">
                      <div>
                        <p className="body-2">
                          {(
                            (rerataWH / convertToMinutes(totalWH)) *
                            100
                          ).toFixed(0)}
                          % Permormance{" "}
                        </p>
                      </div>
                      <Progress
                        color={
                          (rerataWH / convertToMinutes(totalWH)) * 100 > 50
                            ? "success"
                            : "danger"
                        }
                        className="progress-xs"
                        value={(rerataWH / convertToMinutes(totalWH)) * 100}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> Kinerja
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">{instansi}</CardTitle>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <FormGroup>
                    <label>Tanggal awal</label>
                    <Input
                      type="date"
                      value={tanggalAwal}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const awal = ambilTanggal(date);

                        setTanggalAwal(awal);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Tanggal akhir</label>
                    <Input
                      type="date"
                      value={tanggalAkhir}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const akhir = ambilTanggal(date);

                        setTanggalAkhir(akhir);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <Button onClick={loadingData}>Load</Button>
                </Col>
                <Col>
                  <Col md={3}>
                    <ReactHTMLTableToExcel
                      className="btn"
                      table="laporBulan"
                      filename={
                        "Rekap DHO " +
                        tanggalAwal +
                        " - " +
                        tanggalAkhir +
                        " " +
                        instansi +
                        new Date()
                      }
                      sheet="Data "
                      buttonText="Export to Exl"
                    />
                  </Col>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ overflowY: "auto" }}>
              <Table id="laporBulan">
                <thead className="text-primary">
                  <tr>
                    <th>No</th>
                    <th>Profil</th>

                    <th>Nama</th>
                    <th>Jam Kerja </th>
                    <th>Hadir</th>
                    <th>Dinas Luar</th>
                    <th>Izin</th>
                    <th>Sakit</th>
                    <th>Tugas Belajar</th>
                    <th>Cuti</th>
                    <th>Total Jam Kerja</th>
                    <th>Performance</th>
                  </tr>
                </thead>

                <tbody>
                  {getListDHResult ? (
                    Object.keys(getListDHResult).map((key, index) => (
                      <tr key={key}>
                        <td>{index + 1}</td>

                        <td>
                          <img
                            src={`https://smartdh.rotendaokab.go.id/uploads/${getListDHResult[key].uid}.jpg`}
                            alt={"avatar"}
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 25,
                              borderWidth: 8,
                              borderColor: "red",
                            }}
                          />
                        </td>

                        <td>{getListDHResult[key].nama}</td>
                        <td>{getListDHResult[key].workingHours} </td>
                        <td>{getListDHResult[key].hadir}</td>
                        <td>{getListDHResult[key].dinasLuar} </td>
                        <td>{getListDHResult[key].izin} </td>
                        <td>{getListDHResult[key].sakit} </td>
                        <td>{getListDHResult[key].tugasBelajar} </td>
                        <td>{getListDHResult[key].cuti} </td>
                        <td>{totalWH} </td>
                        <td className="text-center" style={{ width: 150 }}>
                          {" "}
                          <div className={`d-flex justify-content-between`}>
                            <div className="d-flex flex-column mt-3">
                              <div>
                                <p className="body-2">
                                  {getListDHResult[key].workingHours
                                    ? Math.round(
                                        (convertToMinutes(
                                          getListDHResult[key].workingHours
                                        ) /
                                          convertToMinutes(totalWH)) *
                                          100
                                      )
                                    : 0}
                                  % terpenuhi{" "}
                                </p>
                              </div>
                              <Progress
                                color={
                                  (convertToMinutes(
                                    getListDHResult[key].workingHours
                                  ) /
                                    convertToMinutes(totalWH)) *
                                    100 >
                                  50
                                    ? "success"
                                    : "danger"
                                }
                                className="progress-xs"
                                value={
                                  (convertToMinutes(
                                    getListDHResult[key].workingHours
                                  ) /
                                    convertToMinutes(totalWH)) *
                                  100
                                }
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : getListDHLoading ? (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  ) : getListDHError ? (
                    <tr>
                      <td colSpan="6" align="center">
                        {getListDHError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
